<template>
  <a-carousel arrows autoplay>
    <div
      slot="prevArrow"
      class="custom-slick-arrow"
      style="left: 44.5px; z-index: 1"
    >
      <img
        class="arrow-left"
        src="../../../assets/images/arrow-left.png"
        alt=""
      />
    </div>
    <div slot="nextArrow" class="custom-slick-arrow" style="right: 44.5px">
      <img
        class="arrow-right"
        src="../../../assets/images/arrow-right.png"
        alt=""
      />
    </div>
    <div>
      <img
        class="slideshow-one"
        src="../../../assets/images/slideshow-one.jpg"
        alt=""
      />
    </div>
    <div>
      <img
        class="slideshow-one"
        src="../../../assets/images/slideshow-two.jpg"
        alt=""
      />
    </div>
  </a-carousel>
</template>
<script>
export default {};
</script>
<style scoped>
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}
.ant-carousel >>> .custom-slick-arrow {
  width: 30px;
  height: 60px;
  margin-top: -30px;
}
.ant-carousel >>> .slick-dots-bottom {
  bottom: 5%;
}
.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel >>> .slick-slide img {
  display: inline-block;
}
.arrow-left {
  width: 30px;
  height: 60px;
}
.arrow-right {
  width: 30px;
  height: 60px;
}
.slideshow-one {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1200px) {
  .container {
    width: 90%;
  }
  .arrow-left,
  .arrow-right {
    display: none;
  }
}
</style>
