<template>
  <div>
    <header>
      <div class="container">
        <a-row>
          <a-col :xl="6" :md="10">
            <h2 class="logo">
              <a href="/">中保数智</a>
            </h2>
          </a-col>
          <a-col :xl="10" :xs="0">
            <!-- <nav class="nav">
              <a-row>
                <a-col :xl="5" class="nav-item">首页</a-col>
                <a-col :xl="5" class="nav-item">创新产品</a-col>
                <a-col :xl="5" class="nav-item">新闻中心</a-col>
                <a-col :xl="5" class="nav-item">关于我们</a-col>
              </a-row>
            </nav> -->
          </a-col>
          <a-col :xl="8" :md="14" :xs="0">
            <div class="hotline">
              <img src="../assets/images/phone.png" alt="" />
              <span>400-800-3879</span>
            </div>
          </a-col>
        </a-row>

        <!-- <div class="nav-mobile">菜单</div> -->
        <!-- <div class="nav-mobile">
          <a-button type="primary" @click="showDrawer"> 菜单 </a-button>
          <a-drawer
            title="Basic Drawer"
            placement="right"
            :closable="false"
            :visible="visible"
            :after-visible-change="afterVisibleChange"
            @close="onClose"
          >
            <p>首页</p>
            <p>创新产品</p>
            <p>新闻中心</p>
            <p>关于我们</p>
            <div class="hotline">
              <img src="../assets/images/phone.png" alt="" />
              <span>400-800-3879</span>
            </div>
          </a-drawer>
        </div> -->
      </div>
    </header>

    <slideshow class="slideshow"> </slideshow>
    <city class="city"></city>

    <div class="advantage">
      <advantage></advantage>
    </div>

    <home-main></home-main>

    <partner class="partner"></partner>

    <pagefooter class="footer">
      
    </pagefooter>
  </div>
</template>


<script>
// @ is an alias to /src
import slideshow from "../views/home/components/Slideshow";
import advantage from "../views/home/components/Advantage";
import partner from "../views/home/components/Partner";
import city from "../views/home/components/city";
import homeMain from "../views/home/components/Homemain";

// import Menu from "./home/components/Menu.vue";
import pagefooter from "../views/home/components/pagefooter";
export default {
  // data() {
  //   return {
  //     visible: false,
  //   };
  // },
  // methods: {
  //   afterVisibleChange(val) {
  //     console.log("visible", val);
  //   },
  //   showDrawer() {
  //     this.visible = true;
  //   },
  //   onClose() {
  //     this.visible = false;
  //   },
  // },
  name: "Home",
  components: {
    slideshow,
    advantage,
    homeMain,
    partner,
    city,

    // Menu,
    pagefooter,
  },
};
</script>
<style scoped>
.container {
  width: 1200px;
  margin: 0 auto;
}
header {
  position: relative;
  width: 100%;

  background: #ffffff;
}
.logo {
  width: 100%;
  height: 100%;
}
.logo a {
  display: inline-block;
  width: 100%;
  height: 100px;
  background: url("./../assets/images/logo.png") no-repeat left 30px;
  background-size: 204.5px 53px;

  text-indent: -9999px;
}
.nav-mobile {
  position: absolute;
  right: 30px;
  top: 30px;
  display: none;
}
.nav {
  margin-top: 45px;
  line-height: 1;
}
.nav .nav-item {
  font-size: 20px;
  color: #333333;
}
.hotline {
  margin-top: 35px;
  line-height: 1;
  width: 100%;
  text-align: right;
}

.hotline img {
  height: 35px;
  padding-right: 18.5px;
  vertical-align: middle;
}
.hotline span {
  font-size: 31px;
  font-weight: bold;
  color: #2974bb;
  vertical-align: middle;
}
/* @media screen and (max-width: 1200px) {
  .container {
    width: 90%;
  }
  .logo a {
    background-position: 0;
  }
} */
@media screen and (max-width: 768px) {
  .logo a {
    background-position: center center;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    width: 90%;
  }
  .nav-mobile {
    display: block;
  }
}

.title-city {
  margin: 73.5px auto;
}
.title-text {
  font-size: 42px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}
.title-englishname {
  font-size: 19px;
  font-weight: 400;
  color: #333333;
  text-align: center;
}

.card-item {
  text-align: center;
}

.card-title {
  font-size: 17px;
  font-weight: 400;
  color: #333333;
  margin-top: 14px;
}
.card-explain {
  font-size: 13px;
  padding: 0 20px;

  font-weight: 400;
  color: #666666;

  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 40px;
}

.card-img {
  width: 83px;
  height: 83px;
  background: #f8f8f8;
  border-radius: 50%;
}
@media screen and (max-width: 1200px) {
  .card-item {
    padding: 25px;
  }
  .line::after {
    display: none;
  }
}
.title {
  font-size: 38px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  padding-bottom: 22.5px;
  margin-top: 123.5px;
}
.title-name {
  font-size: 17px;

  font-weight: 400;
  color: #333333;
  text-align: center;
  padding-bottom: 61px;
}
.footer {
  padding: 20px 0;

  background-color: #10284c;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  color: #f9fbfc;
}

.advantage {
  margin-top: 80px;
}
.results {
  margin-top: 80px;
  padding: 26.5px;
}
.partner {
  margin-top: 123.5px;
}
.footer {
  margin-top: 50px;
}
</style>
